module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M3CLGS","includeInDevelopment":false,"routeChangeEventName":"ignore-me","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"finishcz","short_name":"finishcz","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/husky-site/node_modules/@husky-x/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0c74dc44cb807c71470bd541b3834402"},
    },{
      plugin: require('../node_modules/@phx-husky/gatsby-cookie-consent-husky2/gatsby-browser.js'),
      options: {"plugins":[],"clientID":"208cd055c45a49c9a1b874fe394a829e","clientSecret":"virD3px42LhsIUdXXeXRJKnM7aELP4r0","websiteUrl":"finishinfo.cz","activeEnv":"production"},
    },{
      plugin: require('../node_modules/@husky-x/gatsby-theme-husky/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"finishcz","short_name":"finishcz","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/@husky-x/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"690f0b1592eed159ec89b2941e1d43ba"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
