import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>PROZKOUMEJTE SROVNÁNÍ PRODUKTŮ</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkty/',
    label: 'PODÍVEJTE SE NA VŠECHNY PRODUKTY',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul> <li><sup>*</sup>plně naplněná myčka špinavým nádobím</li> <li><sup>**</sup>vs Finish Power dle průměrné váhy</li> <li><sup>***</sup>účinně odstraňuje připálené nečistoty</li> <li><sup>****</sup>ingredience: enzymy</li> </ul>',
      rows: [
        {
          text: 'Čistota',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltea87c4c628b79c83/672b332d92fc2e334ae4afc0/clean.svg',
        },
        {
          text: 'Lesk',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: '48h zaschlé skvrny<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta2d07ab69b81d7e0/672b332e385a9f306c89a14c/dried-on.svg',
        },
        {
          text: 'Odolnost vůči mastnotě',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4e78d00db65bd109/672b332ef9f8c98e1f5cd550/tough-on-grease.svg',
        },
        {
          text: 'Bez předmývání',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb7dea65dbe363da4/6745d0ce6b0aab7175f21058/icon-skip-pre-rinse.svg',
        },
        {
          text: 'Kapsle bez obalu',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7a74010583deeaf3/6745d0ce6443359f0173e94f/icon-capsule-without-packing.svg',
        },
        {
          text: 'Rychlé rozpouštění',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltce0e76ec7ad8c2ff/672b332ee26ed7180f4b15a8/fast-dissolving.svg',
        },
        {
          text: 'Menší chemická váha<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt4f5999a44f766119/672b332ea9c28979b0f0865e/less-chemical-weight.svg',
        },
        {
          text: 'Ochrana skla',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt93ba453d16253a88/672b332e44232cd2d703667d/glass-protection.svg',
        },
        {
          text: 'Posílení svěžesti',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt6bce8566a25bce5a/672b332eaf4968d9c2937157/freshness-boost.svg',
        },
        {
          text: 'Připálené svkrny<sup>***</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte051fb1e44ab0838/672b332ef082aeb9a04ebbfb/burnt-on-stains.svg',
        },
        {
          text: 'Správná ingredience ve správný čas<sup>****</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt80da07055c78fedf/672b332ea688993417c53602/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt08b5189ea754374b/6745d0cee749702bd14a1781/ul-plus-group-ro.webp',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkty/kapsle/finish-ultimate-plus-all-in-1/45-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt412dc7fde5eb3f74/6745d0ce00b14cc60607ec15/ult-group-ro.webp',
          productName: 'ULTIMATE',
          productLink: '/produkty/kapsle/ultimate-all-in-1/60-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltc945a2c585fafbb2/6745d0cec180342f396aa184/quantum-group-ro.webp',
          productName: 'QUANTUM',
          productLink: '/produkty/kapsle/quantum-all-in-1/72-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt02b4cd4f07f8bdd4/6745d0cec458e95978aa470f/power-group-48-ro.webp',
          productName: 'POWER',
          productLink: '/produkty/tablety/power-all-in-1/94-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd37b7bf697699c8c/6745d0cd7279214a4903a21b/ult-50.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkty/kapsle/ultimate-all-in-1/60-regular/',
        text: 'PROZKOUMEJTE ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'ODOLNÝ VŮČI MASTNOTĚ',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd91d514e360acde8/672b2b6aa714b7238e595eb2/dried-on-stains.webp',
          name: '<strong>48 H</strong> ZASCHLÉ SKVRNY',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt35e61c257cbd1741/6745d0cea3cfe781c09a4ec8/ult-plus-45.webp',
      cardStyle: 'black',
      button: {
        link: '/produkty/kapsle/finish-ultimate-plus-all-in-1/45-regular/',
        text: 'PROZKOUMEJTE NÁŠ NEJLEPŠÍ ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt116ceb8c905face0/672b2b6a07e8b65425b5cb3d/extra-cleaning-power.webp',
          name: '<strong>3X</strong> SILNĚJŠÍ ČISTICÍ SÍLA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltd2797c2b7eca1490/672b2b6ad58fe80ac644cb9d/tough-on-grease-gold.webp',
          name: 'ODOLNÝ VŮČI MASTNOTĚ',
          styleType: 'text-on-image',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt56b7c2f9ec89726f/672b2b6a570b83949bb5c91b/burnt-on-stains.webp',
          name: 'PŘIPÁLENÉ SKVRNY',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt09abdd3605fc26ba/6745d0cebcfd5b3805621f04/quntum-60.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkty/kapsle/quantum-all-in-1/72-regular/',
        text: 'PROZKOUMEJTE QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt14c2a732b85ffe95/672b2b6adef94577005dc577/tough-on-grease.webp',
          name: 'ODOLNÝ VŮČI MASTNOTĚ',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
